<template>
  <div>
    <p>
      <b>
        Mapa de Distritos Electorales
      </b>
    </p>
    <p>
      El mapa resalta en los
      <span v-if="esDistritos">
        Distritos
      </span>
      <span v-else>
        Municipios
      </span>
      Electorales el Partido Político, Coalición o Candidatura Independiente que lleva ventaja hasta el momento.
    </p>
    <!--<img src="@/assets/Distritos.svg" alt="" class="img-fluid mx-auto" v-if="esDistritos">
    <img src="@/assets/Municipios.svg" alt="" class="img-fluid mx-auto" v-else>-->
    <button @click="salirMapa" class="btn-salir">Salir del Mapa</button>
    <SvgPanZoom 
        style="width: 100%; height: 650px; border:1px solid black;"
        :zoomEnabled="true"
        :controlIconsEnabled="false"
        :fit="true"
        :center="true"
        @svgpanzoom="registerSvgPanZoom"
    >
          <svg-map
            class="mapa-distritos"
            style="width:100% !important; height:100%;"
            :map="Municipios"
            v-if="!esDistritos"
          />
          <svg-map
            class="mapa-distritos"
            style="width:100% !important; height:100%;"
            :map="Distritos"
            v-else
          />
    </SvgPanZoom>
  </div>
</template>

<script>
import { SvgMap } from "vue-svg-map";
import SvgPanZoom from 'vue-svg-pan-zoom';
import Distritos from "@/assets/distritos";
import Municipios from "@/assets/municipios";
export default {
  name: 'MapaDistritos',
   components: {
    SvgMap,
    SvgPanZoom 
  },
   data() {
    return {
      Municipios,
      Distritos,
      mapa: Array,
      region: {
        type: Array,
        defautl: () => []
      },
      seleccionRegion: false,
      seleccion: Object,
      tooltipActivo: false,
      svgpanzoom: null
    };
  },
  props: {
    detalle: Object,
    esDistritos: {
      type: Boolean,
      default: () => false
    }
  },
  mounted: function() {
    this.cargarEventos();
  },
  watch: {
    esMapa: {
      handler(val) {
        this.$nextTick(() => {
          if (val) {
            this.seleccionRegion = false;
            this.cargarEventos();
          }
        });
      },
      $nextTick: true
    }
  },
  computed:{
    regionSeleccionada() {
      if (this.$route.params.eleccion === "D") {
        return this.$store.state.dVotosMapaTarjetas;
      } else if (this.$route.params.eleccion === "A") {
        return this.$store.state.aVotosMapaTarjetas;
      }
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    esMapa() {
      return this.detalle.esMapa;
    }
  },
  methods:{
    registerSvgPanZoom(svgpanzoom) {
      this.svgpanzoom = svgpanzoom;
    },
    salirMapa(){
      this.svgpanzoom.resetZoom();
      this.svgpanzoom.center();
    },
    cargarEventos: function() {
       var elemento = document.getElementsByClassName("svg-map__location");

      elemento.forEach(r => {
        r.addEventListener(
          "mouseover",
          function(e) {
            this.desplegarRegion(e, r);
          }.bind(this),
          false
        );

        r.addEventListener(
          "click",
          function() {
            this.detalleMapa(r);
          }.bind(this),
          false
        );
        var color = "";
        if (this.eleccion === "D") {
          color = this.regionSeleccionada.datos_distritos.filter(
            t => t.distrito_id == r.getAttribute("finder")
          )[0].candidatura_ganadora_color;
        } else {
          color = this.regionSeleccionada.datos_municipios.filter(
            t => t.municipio_id == r.getAttribute("finder")
          )[0].candidatura_ganadora_color;
        }
        r.style.fill = color;
      }, this);

      this.mapa = elemento;
    },
    mostrarRegion: function() {
      this.seleccionRegion = false;
      this.mapa.forEach(m => {
        m.classList.remove("opacidad_mapa");
      });
    },
    detalleMapa: function(r) {
      this.$router.push(
        `/${this.eleccion}/DET/VPC/${r.getAttribute("finder")}`
      );
      this.actualizarEstado(r);
      this.detalle.datos.elemento = r;
    },
    actualizarEstado: function(elemento) {
      this.detalle.esMapa = false;
      this.detalle.region = elemento;
      this.detalle.esDiputaciones = this.esDiputaciones;
      this.$emit("childToParent", this.detalle);
    },
  }
}
</script>

<style lang="scss" scoped>
.btn-salir {
  /* position: absolute;
  right: 0px;
  margin-right: 20px; */
  float: right;
}
</style>
