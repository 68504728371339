<template>
  <b-modal
    id="DetalleEleccionCandidaturasModal"
    size="lg"
    title="Detalle"
    header-bg-variant="info"
    hide-footer
    no-close-on-backdrop
    centered
  >
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-12">
          <div class="resumen">
            <p class="font-weight-bold mb-0 subtitulo">Total de votos</p>
            <h1 class="total primario">
              <span>
                {{ resumen.total_votos_general_numero }}
              </span>
            </h1>

            <p class="porcentaje primario">
              <span> <!--Es candidato-->
                {{ resumen.total_votos_general_porcentaje }}%
              </span>
            </p>
          </div>
        </div>
        <div class="col-md-9 col-12">
          <div v-if="seleccionados" class="row mb-12">
            <div
              class="detalle-card col-12"
              v-for="(seleccionado, index) in seleccionados"
              :key="index"
            >
              <DetalleCardEscritorio
                v-if="anchoPantalla >= 992"
                :esXDistrito="esXDistrito"
                :esXSeccion="esXSeccion"
                :seleccionado="seleccionado"
              />
              <DetalleCardMovil
                v-else
                :esXDistrito="esXDistrito"
                :esXSeccion="esXSeccion"
                :seleccionado="seleccionado"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-md-3 col-12">
          <b-btn
            variant="primary"
            class="mx-auto d-block"
            @click="seen = !seen"
            v-if="!tresSeleccionados"
          >
            Agregar
          </b-btn>
        </div>
      </div>
      <div class="opciones" v-if="seen && seleccionados.length < 3">
        <p class="leyenda">
          Puedes agregar hasta tres opciones con el botón
          <font-awesome-icon icon="plus" class="fa-1x" />
        </p>

        <SeleccionDetalleEscritorio
          v-if="anchoPantalla >= 992"
          :noSeleccionados="noSeleccionados"
        />
        <SeleccionDetalleMovil v-else :noSeleccionados="noSeleccionados" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import useDetalleEleccion from '@/composables/useDetalleEleccion';

import DetalleCardEscritorio from '@/components/Escritorio/componentes/DetalleCard.vue';
import DetalleCardMovil from '@/components/Movil/componentes/DetalleCard.vue';

import SeleccionDetalleEscritorio from '@/components/Escritorio/componentes/SeleccionDetalle.vue';
import SeleccionDetalleMovil from '@/components/Movil/componentes/SeleccionDetalle.vue';

export default defineComponent({
  name: 'DetalleEleccionCandidaturas',
  components: {
    DetalleCardEscritorio,
    DetalleCardMovil,
    SeleccionDetalleEscritorio,
    SeleccionDetalleMovil,
  },
  props: {
    resumen: {
      type: Object,
      default: () => {},
      required: true,
    },
    esPartidos: {
      type: Boolean,
      default: () => false,
    },
    esCandidato: {
      type: Boolean,
      default: () => false,
    },
    esXDistrito: {
      type: Boolean,
      default: () => false,
    },
    esXSeccion: {
      type: Boolean,
      default: () => false,
    },
    esXAyuntamiento: {
      type: Boolean,
      default: () => false,
    },
    votos: {
      default: () => {},
      required: true,
    },
  },
  setup(props, { root }) {
    return {
      ...useDetalleEleccion(props, root),
    };
  },
});
</script>

<style lang="scss" scoped>
.resumen {
  max-width: 250px;
  margin: 0 auto;

  .subtitulo {
    font-size: 1.2rem;
  }

  .total {
    font-weight: bold;
    font-size: 4rem;
    margin-bottom: 0;
  }

  .porcentaje {
    font-weight: 500;
    text-align: right;
  }
}

.detalle-card {
  .grilla {
    display: grid;
    grid-template-columns: 1fr 50px;

    .detalles {
      svg {
        max-width: 75px;
      }
    }

    .borrar {
      button {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

.opciones {
  margin: 100 50 50 50;
}

.leyenda {
  font-style: bold;
  margin-top: 100px;
}

.imagenPartido {
  width: 20px;
  height: 20px;
}

.tdAvatar {
  width: 50px;
}

.texto-votos{
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
}

.texto-porcentaje{
  font-size: 14px;
  font-weight: normal;
}

.hide {
  visibility: hidden !important;
}
</style>
