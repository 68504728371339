<template>
  <div class="row">
    <div class="col-3 text-center">
      <img
        :src="seleccionado.candidato_foto_url"
        style="max-height:40px;"
        class="mb-2 img-fluid"
        alt=""
      />
      <p class="font-weight-bold mb-0" style="font-size:12px">
        {{ seleccionado.candidato_nombre }}
      </p>
      <p v-if="seleccionado.candidato_apellidos" style="font-size:12px">
        {{ seleccionado.candidato_apellidos }}
      </p>
    </div>
    <div class="col-8" style="border:1px solid #dee2e6">
      <div class="row partidos">
        <div class="float-left mb-2">
          <img
            :src="seleccionado.candidatura_logo"
            style="max-height:40px;"
          />
        </div>
      </div>
      <div class="row grilla border p-2">
        <div class="detalles">
          <!--<avatar :colores="colores" v-if="!esPartidos || esCandidato" />-->
          <div class="grilla">
            <div class="total-votos col-mb-2">
              <p class="mb-0">
                Total de votos <br />

                <b v-if="esXDistrito" class="texto-votos">
                  {{ seleccionado.candidatura_total_votos_numero }}
                </b>
                <b v-else-if="esXSeccion" class="texto-votos">
                  {{ seleccionado.candidatura_total_votos_numero }}
                </b>
                <b v-else class="texto-votos">
                  {{ seleccionado.candidatura_total_votos_general_numero }}
                </b>

                <b v-if="esXDistrito"  class="texto-porcentaje">
                    {{ seleccionado.candidatura_total_votos_porcentaje }}%
                </b>
                <b v-else-if="esXSeccion"  class="texto-porcentaje">
                    {{ seleccionado.candidatura_total_votos_porcentaje }}%
                </b>
                <b v-else class="texto-porcentaje">
                    {{ seleccionado.candidatura_total_votos_general_porcentaje }}%
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <b-progress class="row"
        v-if="esXDistrito"
        :value="seleccionado.candidatura_total_votos_porcentaje"
        max="100"
      />
      <b-progress class="row"
        v-else-if="esXSeccion"
        :value="seleccionado.candidatura_total_votos_porcentaje"
        max="100"
      />
      <b-progress class="row"
        v-else
        :value="seleccionado.candidatura_total_votos_general_porcentaje"
        max="100"
      />
    </div>
    <div class="col-1 align-self-center">
        <div class="borrar mt-3">
          <button @click="seleccionado.seleccionado = false">
            <font-awesome-icon icon="trash-alt" class="fa-2x" />
          </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetalleCardEscritorio',
  props: {
    seleccionado: Object,
    esXSeccion: Boolean,
    esXDistrito: Boolean,
  },
};
</script>
