<template>
  <div class="grilla-seleccionar">
    <div
      class="elemento"
      v-for="(candidato, index) in noSeleccionados"
      :key="index"
    >
      <div class="avatar">
        <img
          src="@/assets/avatar-persona.svg"
          alt=""
          style="max-height:40px;"
        />
      </div>
      <div class="detalle">
        <div>
          <span>{{ candidato.candidato_nombre }} {{ candidato.candidato_apellidos }}</span> <br />
          <div class="float-left mb-2">
            <img
              :src="candidato.candidatura_logo"
              style="max-height:40px;"
            />
          </div>
        </div>
      </div>
      <div class="boton">
        <button @click="candidato.seleccionado = true">
          <font-awesome-icon icon="plus" class="fa-2x" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeleccionDetalleEscritorio',
  props: {
    noSeleccionados: Array
  }
}
</script>

<style lang="scss" scoped>
.grilla-seleccionar {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .elemento {
    display: grid;
    grid-template-columns: 20% 1fr 15%;
    align-items: center;
    padding: 1rem;

    &:nth-child(1) {
      background: rgba(0,0,0,.05);
    }
    &:nth-child(2) {
      background: rgba(0,0,0,.05);
    }
    &:nth-child(5) {
      background: rgba(0,0,0,.05);
    }
    &:nth-child(6) {
      background: rgba(0,0,0,.05);
    }
    &:nth-child(9) {
      background: rgba(0,0,0,.05);
    }
    &:nth-child(10) {
      background: rgba(0,0,0,.05);
    }
    &:nth-child(13) {
      background: rgba(0,0,0,.05);
    }
    &:nth-child(14) {
      background: rgba(0,0,0,.05);
    }
  }
}
</style>
