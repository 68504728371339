<template>
  <div>
    <PreguntasFrecuentesEscritorio v-if="anchoPantalla >= 992" />
    <PreguntasFrecuentesMovil v-else />
  </div>
</template>

<script>
import PreguntasFrecuentesEscritorio from '@/components/Escritorio/vistas/PreguntasFrecuentes.vue'
import PreguntasFrecuentesMovil from '@/components/Movil/vistas/PreguntasFrecuentes.vue'

export default {
  name: 'PreguntasFrecuentes',
  components: {
    PreguntasFrecuentesEscritorio,
    PreguntasFrecuentesMovil
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Preguntas Frecuentes'
  },
  computed: {
    anchoPantalla () {
      return this.$store.state.anchoPantalla
    }
  },
}
</script>
