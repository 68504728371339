<template>
  <div class="grilla-superior">
    <div class="datos">
      <div class="grilla-interna mb-2">
        <img
          src="@/assets/avatar-persona.svg"
          style="width: 5rem;"
        />
        <div class="partidos">
          <img
            :src="seleccionado.candidatura_logo"
            class="mb-2"
            style="max-height:40px;"
          />
        </div>
      </div>

      <p>
        <b>
          {{ seleccionado.candidato_nombre }} {{ seleccionado.candidato_apellidos }}
        </b>
      </p>

      <div class="grilla-interna-estadistica">
        <div class="votos">
          <p class="mb-0">
            Votos <br />
            <b v-if="esXDistrito" class="texto-votos">
              {{ seleccionado.candidatura_total_votos_numero }}
            </b>
            <b v-else-if="esXSeccion" class="texto-votos">
              {{ seleccionado.candidatura_total_votos_numero }}
            </b>
            <b v-else class="texto-votos">
              {{ seleccionado.candidatura_total_votos_general_numero }}
            </b>
          </p>
        </div>
        <div class="porcentaje">
          <p class="mb-0">
            Porcentaje <br />
            <b v-if="esXDistrito"  class="texto-porcentaje">
              {{ seleccionado.candidatura_total_votos_porcentaje }}%
            </b>
            <b v-else-if="esXSeccion"  class="texto-porcentaje">
              {{ seleccionado.candidatura_total_votos_porcentaje }}%
            </b>
            <b v-else class="texto-porcentaje">
              {{ seleccionado.candidatura_total_votos_general_porcentaje }}%
            </b>
          </p>
        </div>
      </div>

      <b-progress
        v-if="esXDistrito"
        class="progreso-detalle-movil"
        :value="seleccionado.candidatura_total_votos_porcentaje"
        max="100"
      />
      <b-progress
        v-else-if="esXSeccion"
        class="progreso-detalle-movil"
        :value="seleccionado.candidatura_total_votos_porcentaje"
        max="100"
      />
      <b-progress
        class="progreso-detalle-movil"
        v-else
        max="100"
        :style="`background-color: #e2e2e2;`"
      >
        <b-progress-bar
          :style="`background-color: #${color}; !important;`"
          :value="seleccionado.candidatura_total_votos_general_porcentaje"
        />
      </b-progress>
    </div>
    <div class="eliminar">
      <button @click="seleccionado.seleccionado = false" class="float-right">
        <font-awesome-icon icon="trash-alt" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetalleCardMovil',
  props: {
    seleccionado: Object,
    esXSeccion: Boolean,
    esXDistrito: Boolean,
  },
  computed: {
    color() {
      return Math.floor(Math.random() * 16777215).toString(16);
    },
  },
};
</script>

<style lang="scss" scoped>
.grilla-superior {
  display: grid;
  grid-template-columns: 1fr 10%;
  align-self: flex-start;
  padding: 5px;

  .datos {
    .grilla-interna {
      display: grid;
      grid-template-columns: 6rem 1fr;
      align-items: flex-end;
      gap: 10px;
    }

    .grilla-interna-estadistica {
      display: grid;
      grid-template-columns: 1fr 1fr;
      z-index: 1;
      position: relative;
    }
  }

  .eliminar {
    button {
      background-color: transparent;
      border: none;
      font-size: 1.7rem;
    }
  }
}

.progreso-detalle-movil {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
  height: 33px;
  z-index: auto;
}

.texto-porcentaje, .texto-votos {
  color: #fff !important;
}
</style>
