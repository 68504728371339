<template>
  <div class="col-12 col-md-6 mb-4">
    <div
      class="resumen-voto-candidatura"
      :class="ganador == candidato.candidato_id ? 'ganador' : ''"
    >
      <div class="grilla-datos-candidatura">
        <div class="foto">
          <img :src="candidato.candidato_foto_url" :alt="candidato.candidato_nombre">
          <!--<avatar :colores="colores" /> -->
        </div>
        <div class="nombre">
          <b>{{candidato.candidato_nombre}}</b>
          <br />
          <b>{{candidato.candidato_apellidos}}</b>
        </div>
        <div class="seleccion">
          <!-- <input type="checkbox" v-model="candidato.seleccionado"> -->
          <b-form-checkbox
            v-if="candidato.seleccionado"
            v-model="candidato.seleccionado"
          />
          <b-form-checkbox
            v-else
            v-model="candidato.seleccionado"
            :disabled="tresSeleccionados"
          />
        </div>
      </div>
      <div class="grilla-votos mb-2">
        <div class="total text-left">
          <p class="mb-0 font-weight-bold">
            Total de votos <br />
            <span>
              {{ candidato.candidatura_total_votos_general_numero }}
            </span>
          </p>
        </div>
        <div class="porcentaje text-right">
          <p class="mb-0 font-weight-bold">
            <span>
              {{ candidato.candidatura_total_votos_general_porcentaje}}%
            </span>
          </p>
        </div>
      </div>
      <div class="grilla-distribucion-votos">
        <div class="entidad">
          <p class="font-weight-bold">
            Votos
          </p>
          <p class="mb-0">
            En la Entidad <br/>
          </p>
          <p class="mb-0 text-right">
            <span class="mr-3 font-weight-bold small">
              {{ candidato.candidatura_total_votos_entidad_numero }}
            </span>
            <span class="small">{{ candidato.candidatura_total_votos_entidad_porcentaje}}%</span>
          </p>
        </div>
        <div class="exranjero">
          <p class="mb-0">
            En en Extranjero <br/>
          </p>
          <p class="mb-0 text-right">
            <span class="mr-3 font-weight-bold small">
              {{ candidato.candidatura_total_votos_extranjero_numero }}
            </span>
            <span class="small">
              {{ candidato.candidatura_total_votos_extranjero_porcentaje }}%
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default {
  name: 'VotoPorCandidaturaMovil',
  props: {
    candidato: {
      type: Object,
      default: () => {},
      required: true,
    },
    ganador: {
      type: Number,
      required: true,
    },
    tresSeleccionados: Boolean,
  },
  computed: {
    colores() {
      return obtenerColoresDeAvatar(this);
    },
  },
};
</script>

<style lang="scss" scoped>
.ganador {
  border: 3px solid #d2127e;
  .grilla-votos {
    p > span {
      color: #d2127e;
    }
  }
}

.resumen-voto-candidatura {
  background-color: #f5f5f5;
  padding: 10px;

  .grilla-datos-candidatura {
    display: grid;
    grid-template-columns: 75px 1fr 30px;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    .foto {
      img {
        max-width: 50px;
        display: block;
        margin: 0 auto;
      }
    }

    .nombre {
      font-size: 16px;
    }
  }

  .grilla-votos, .grilla-distribucion-votos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;
  }

  .grilla-distribucion-votos {
    background-color: #d3d3ec;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    padding: 10px;

    .entidad {
      border-right: 3px solid #2c2c2c;
      padding-right: 15px;
    }
  }
}
</style>
