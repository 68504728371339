<template>
  <layout>
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <h4>
          {{ titulo }} -
          <b class="primario">Entidad</b> -
          <span class="primario">{{ subtitulo }}</span>
        </h4>

        <p>
          <b>
            Baja California Sur
          </b>
        </p>

        <p>
          El total de votos mostrado a nivel Entidad representa la suma del voto emitido en
          territorio Estatal y en el Extranjero.
        </p>

        <p class="mb-5">
          Por representación, los decimales de los porcentajes muestran sólo cuatro digitos.
          No obstante, al considera todos los decimales, suman 100%.
        </p>

        <div class="totalVotos mb-5">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumen.total_votos_general_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{ resumen.total_votos_general_porcentaje}}%
          </p>

          <div v-if="eleccion === 'G'">
            <p class="primario mb-0 font-weight-bold">
              Total de votos en:
            </p>
            <div class="grilla-votos">
              <div class="votos">
                <p class="mb-2">
                  <b>
                    La Entidad
                  </b>
                </p>

                <h4 class="text-right">
                  {{ resumen.total_votos_por_entidad_numero }}
                </h4>
              </div>
              <div class="votos">
                <p class="mb-2">
                  <b>
                    El Extranjero
                  </b>
                </p>

                <h4 class="text-right">
                  {{ resumen.total_votos_extranjero_numero }}
                </h4>
              </div>
            </div>
          </div>
          <div v-else>
            <MapaDistritos :detalle="detalle" :key="random" :esDistritos="eleccion === 'D'" style="width: 80%; margin: 0 auto;" />
          </div>
        </div>

        <div class="votosEnActasContabilizadas">
          <p class="titulo">
            Votos en Actas Contabilizadas
          </p>

          <p>
            Presiona sobre el recuadro blanco y selecciona de una a
            tres opciones para conocer el detalle de la votación.
          </p>
        </div>

        <div class="row" v-if="eleccion === 'G'">
          <VotoPorCandidatura
            v-for="(candidato, index) in votos.datos_candidatos"
            :key="index"
            :candidato="candidato"
            :ganador="votosXCandidato.candidato_id_con_mayor_numero_votos"
            :tresSeleccionados="tresSeleccionados"
          />
        </div>
        <div class="row resumenCandidaturaDistritos" v-else>
          <div
            class="col-12 col-mb-6 tarjeta"
            v-for="(partido, index) in votos.datos_candidatos"
            :key="index"
          >
            <div
              class="contenedor"
              :style="
                votosXDistritos.candidatura_id_con_mayor_numero_votos === partido.candidatura_id
                  ? calcularColorBorde(partido)
                  : ''
              ">
              <div class="grilla-superior">
                <div class="logo">
                  <img :src="partido.candidatura_logo" alt="">
                </div>
                <div class="seleccionado">
                  <input
                    v-if="!partido.seleccionado"
                    type="checkbox"
                    class="float-right"
                    v-model="partido.seleccionado"
                    :disabled="tresSeleccionados"
                  >
                  <input
                  v-else
                    type="checkbox"
                    class="float-right"
                    v-model="partido.seleccionado"
                  >
                </div>
              </div>
              <div class="grilla-inferior">
                <div class="votos">
                  <p class="mb-0">
                    <b>Total de votos</b> <br />
                    {{ partido.candidatura_total_votos_numero }}
                  </p>
                </div>
                <div class="porcentaje">
                  <p class="mb-0">
                    {{ partido.candidatura_total_votos_porcentaje }}%
                  </p>
                </div>
                <div class="distritos text-right">
                  <div class="mb-0">
                    <div v-if="eleccion === 'D'">
                      <b>Distritos</b><br />{{ partido.candidatura_distritos_obtenidos_numero }}
                    </div>
                    <div>
                      <b>Municipios</b><br />{{ partido.candidatura_municipios_obtenidos_numero }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="resumenDeLaVotacion mb-5">
          <p v-if="eleccion === 'D'">
            No se considera la cantidad de votos asentada en las Actas PREP de la Casilla
            Especial de Representación Proporcional.
          </p>
          <p class="titulo">
            Resumen de la votación
          </p>

          <ResumenVotacion
            v-if="eleccion === 'G'" texto="En la Entidad"
            :numero="resumen.total_votos_por_entidad_numero"
            :porcentaje="resumen.total_votos_por_entidad_porcentaje"
          />
          <ResumenVotacion
            v-if="eleccion === 'G'"
            texto="En el Extranjero"
            :numero="resumen.total_votos_extranjero_numero"
            :porcentaje="resumen.total_votos_extranjero_porcentaje"
          />
          <ResumenVotacion
            texto="Candidaturas no registradas"
            :numero="resumen.total_votos_candidatura_noregistrada_numero"
            :porcentaje="resumen.total_votos_candidatura_noregistrada_porcentaje"
          />
          <ResumenVotacion
            texto="Nulos"
            :numero="resumen.total_votos_nulos_numero"
            :porcentaje="resumen.total_votos_nulos_porcentaje"
          />

          <div class="row">
            <div class="col-12">
              <button
                v-if="eleccion === 'G'"
                class="boton-azul float-right px-4 py-2"
                v-b-modal.DetalleEleccionCandidaturasModal
                :disabled="!alMenosUnSeleccionado"
              >
                Ver detalle
              </button>
              <button
                v-if="eleccion === 'D' || eleccion === 'A'"
                class="boton-azul float-right px-4 py-2"
                v-b-modal.DetalleEleccionDistritosModal
                :disabled="!alMenosUnSeleccionado"
              >
                Ver detalle
              </button>
            </div>
          </div>
        </div>

        <div class="votoEnElExtranjero px-4 py-2 text-center mb-5" v-if="eleccion === 'G'">
          <font-awesome-icon icon="globe-americas" class="fa-3x mb-3" />
          <h4 class="font-weight-bold mb-3">
            Voto en el Extranjero
          </h4>

          <router-link to="/G/VE" class="btn btn-primary mx-auto d-block btn-actas px-4 w-50">
            Ver actas
          </router-link>
        </div>

        <estadistica-entidad />
      </div>

      <detalle-eleccion-candidaturas
        v-if="eleccion === 'G'" :resumen="resumen" :votos="votos" :esCandidato="true"
      />
      <detalle-eleccion-distritos v-if="eleccion === 'D' || eleccion === 'A'"
      :resumen="resumen" :votos="votos" />
    </div>
  </layout>
</template>

<script>
import Layout from '@/components/Movil/layouts/Layout';
import VotoPorCandidatura from '@/components/Movil/componentes/VotoPorCandidatura.vue';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion';
import DetalleEleccionCandidaturas from '@/components/DetalleEleccionCandidaturas.vue';
import DetalleEleccionDistritos from '@/components/DetalleEleccionDistritos.vue';
import MapaDistritos from '@/components/Movil/componentes/MapaDistritos/Index.vue';
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';

export default {
  name: 'VotosPorCandidaturasMovil',
  components: {
    Layout,
    VotoPorCandidatura,
    ResumenVotacion,
    DetalleEleccionCandidaturas,
    MapaDistritos,
    DetalleEleccionDistritos,
    EstadisticaEntidad,
  },
  data() {
    return {
      random: 0,
      esMapa: true,
      votos: [],
    };
  },
  watch: {
    '$route.params.eleccion': function () {
      this.$forceUpdate();
      this.random = Math.random();
    },
  },
  computed: {
    tresSeleccionados() {
      let seleccionados = 0;
      this.votos.datos_candidatos.map((candidato) => {
        if (candidato.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }
        return null;
      });

      return seleccionados === 3;
    },
    titulo() {
      if (this.$route.params.eleccion === 'G') { return 'Gubernatura'; }
      if (this.$route.params.eleccion === 'A') { return 'Ayuntamiento'; }
      return 'Diputaciones';
    },
    resumen() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelEntidad;
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelEntidad;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelEntidad;
      }

      return {};
    },
    detalle() {
      return {
        datos: this.$route.params.eleccion === 'D'
          ? this.$store.state.dVotosMapa : this.$store.state.aVotosMapa,
        eleccion: this.$route.params.eleccion,
        esMapa: this.esMapa,
      };
    },
    esOscuro() {
      return this.$store.state.esOscuro;
    },
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'd2127e';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return '79144c';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return 'efb5d3';
    },
    colorUrbano() {
      if (this.$store.state.esOscuro) {
        return 'f1b1d7';
      }
      return 'd2127e';
    },
    colorNoUrbano() {
      if (this.$store.state.esOscuro) {
        return 'e97ebd';
      }
      return '79144c';
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    subtitulo() {
      if (this.$router.currentRoute.name === 'VotosPorCandidaturas' && (this.$route.params.eleccion === 'G' || this.$route.params.eleccion === 'A')) {
        return 'Votos por Candidatura';
      }
      return 'Votos por Distritos';
    },
    votosXCandidato() {
      return this.$store.state.votosXCandidatoTarjeta;
    },
    votosXDistritos() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosMapa;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosMapa;
      }

      return {};
    },
    alMenosUnSeleccionado() {
      let seleccionados = 0;

      this.votos.datos_candidatos.map((candidato) => {
        if (candidato.seleccionado) {
          // eslint-disable-next-line no-plusplus
          seleccionados++;
        }

        return null;
      });

      return seleccionados >= 1;
    },
  },
  methods: {
    calcularColorBorde(partido) {
      if (!this.esOscuro) {
        return `
          border-top: 2px solid #D3097F !important;
          border-right: 2px solid #D3097F !important;
          border-bottom: 2px solid #D3097F !important;
          border-left-color: ${partido.candidatura_color} !important;
          color: #D3097F !important;
        `;
      } if (this.esOscuro) {
        return `
          border-top: 2px solid #e97ebd !important;
          border-right: 2px solid #e97ebd !important;
          border-bottom: 2px solid #e97ebd !important;
          border-left-color: ${partido.candidatura_color} !important;
          color: #e97ebd !important;
        `;
      }

      return `
        border-left-color: ${partido.color};
      `;
    },
    actualizarDetalleEleccion() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    setearVotos() {
      if (this.cargaTerminada) {
        if (this.eleccion === 'G') {
          this.votos = this.$store.state?.votosXCandidatoTarjeta;
        } else if (this.eleccion === 'D') {
          this.votos = this.$store.state?.dVotosMapa;
        } else {
          this.votos = this.$store.state?.aVotosMapa;
        }
      }
    },
    desplegarRegion(e, r) {
      if (this.seleccionRegion) {
        this.mostrarRegion();
      }
      this.$nextTick(() => {
        this.region.estado = true;
        this.region.map = r;
        this.region.event = e;
        this.region.tipo = this.detalle.eleccion;
        this.region.esDiputaciones = this.esDiputaciones;
        this.seleccionRegion = true;
        this.mapa.forEach((m) => {
          if (m.id !== r.id) {
            m.classList.add('opacidad_mapa');
          } else {
            m.classList.remove('opacidad_mapa');
          }
        });
      });
    },
  },
  created() {
    this.setearVotos();
  },
  updated() {
    this.setearVotos();
  },
};
</script>

<style lang="scss" scoped>
.resumenCandidaturaDistritos {
  .tarjeta {
    margin-bottom: 15px;
    .contenedor {
      border-left: 6px solid;
      background-color: #efefef;
      padding: 15px;
      .grilla-superior {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        margin-bottom: 15px;

        img {
          max-height: 50px;
        }
      }
      .grilla-inferior {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: flex-end;
        gap: 15px;
      }
    }
    /* .ganador {
      border-top: 2px solid #D3097F;
      border-right: 2px solid #D3097F;
      border-bottom: 2px solid #D3097F;
      color: #D3097F;
    } */
  }
}

.votoEnElExtranjero {
  background-image: url('../../../../assets/Fondo_Mapa.png');
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
