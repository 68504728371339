<template>
  <div class="map-tooltip">
    <div class="map-imagen">
      <img class="imagen-candidatura" :src="imagenCandidatura" alt="" v-if="existeCandidaturaGanadora" />
    </div>
    <div class="map-info">
      <div>
        <strong>{{ tipoEleccion() }}</strong>
      </div>
      <div class="map-detalle">
        <div class="map-region">
          <strong><h5>{{ regionId }}. {{ obtenerNombreRegion() }}</h5></strong>
        </div>
        <div @click="actualizarEstado()" class="map-ver">
          Ver detalle del {{ tipoEleccion() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detalle: Object
    };
  },
  props: {
    region: null
  },
  mounted: function() {
    this.ubicacionTooltip(this.region.map);
  },
  computed: {
    existeCandidaturaGanadora() {
      return typeof this.region.datos !== "undefined" ? true : false;
    },
    imagenCandidatura() {
      return this.region.datos.candidatura_logo; 
    },
    regionId() {
      return this.region.map.getAttribute("finder");
    }
  },
  methods: {
    ubicacionTooltip: function(elemento) {
      var x = this.region.event.offsetX;
      var y = this.region.event.offsetY;

      var tooltip = document.querySelector(".map-tooltip");
      tooltip.addEventListener(
        "mouseover",
        function() {
          this.tooltipActivado(true);
        }.bind(this),
        false
      );

      tooltip.addEventListener(
        "mouseleave",
        function() {
          this.tooltipActivado(false);
        }.bind(this),
        false
      );

      tooltip.style.display = "block";

      tooltip.style.left = x + "px";
      tooltip.style.top = y + "px";

      var color = getComputedStyle(elemento);
      tooltip.style.borderColor = color.fill;

    },
    tipoEleccion: function() {
      return this.region.tipo == "D" ? "Distrito" : "Municipio";
    },
    obtenerNombreRegion: function() {
      return this.region.map.getAttribute("name");
    },
    tooltipActivado: function(value) {
      this.$emit("tooltipFocus", value);
    },
    actualizarEstado: function() {
      this.$router.push(`/${this.region.tipo}/DET/VPC/${this.region.map.getAttribute("finder")}`);
    }
  }
};
</script>
