<template>
  <div>
    <VotosPorCandidaturaMovil v-if="anchoPantalla < 992" />
    <VotosPorCandidaturaEscritorio v-else />
  </div>
</template>

<script>
import VotosPorCandidaturaMovil from '@/components/Movil/vistas/VotosPorCandidaturas/Index.vue';
import VotosPorCandidaturaEscritorio from '@/components/Escritorio/vistas/VotosPorCandidaturas';

export default {
  name: 'VotosPorCandidatura',
  components: {
    VotosPorCandidaturaMovil,
    VotosPorCandidaturaEscritorio,
  },
  computed: {
    anchoPantalla() {
      // Aqui obtener el ancho de la pantalla desde Vuex, con el valor
      // podemos usar con un v-if para que renderice uno u el otro
      return this.$store.state.anchoPantalla;
    },
  },
  created() {
    // console.log(this.$router.currentRoute.path)
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Entidad - Votos Por Candidaturas',
  },
};
</script>
