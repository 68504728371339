import { render, staticRenderFns } from "./ResumenMapaAyuntamiento.vue?vue&type=template&id=e132c47c&scoped=true&"
import script from "./ResumenMapaAyuntamiento.vue?vue&type=script&lang=js&"
export * from "./ResumenMapaAyuntamiento.vue?vue&type=script&lang=js&"
import style0 from "./ResumenMapaAyuntamiento.vue?vue&type=style&index=0&id=e132c47c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e132c47c",
  null
  
)

export default component.exports