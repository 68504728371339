<template>
  <div class="vista-escritorio">
    <Cabecera />
    <Cuerpo>
      <div class="votos ">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>
              {{ eleccion }} -
              <span><strong>Entidad</strong> - {{ tituloEleccion }}</span>
            </h2>
            <h4>
              <strong>Baja California Sur</strong>
            </h4>
            <p v-if="eleccion === 'Gubernatura'">
              El total de votos mostrado a nivel Entidad representa la suma del
              voto emitido en territorio Estatal y en el Extranjero. <br />
              Por presentación, los decimales de los porcentajes muestran sólo
              cuatro dígitos. No obstante, al considerar todos los decimales,
              suman 100%.
            </p>
            <p v-else>
              El total de votos calculado y porcentaje que se muestran, se refieren a los votos
              asentados en las actas PREP hasta el momento. <br />
              Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
              obstante, al considerar todos los decimales, suman 100%.
            </p>

            <p v-if="eleccion === 'Ayuntamientos'">
              <b>
                En la tabla se muestra el número de Municipios que cada Partido
                Político, Coalición o Candidatura Independiente ha obtenido
                hasta el momento.
              </b>
            </p>

            <h3>Votos en Actas Contabilizadas</h3>
          </div>
          <div class="visualizar-como" v-if="esMapa">
            <h3>
              Visualiza como:
              <!--{{ esMapa }}-->
            </h3>
            <div>
              <button
                :class="{ 'visualizar-activo': mostrarGrafica }"
                @click="manejarMostrarGrafica(true)"
              >
                <span v-if="eleccion === 'Gubernatura'">
                  <img src="@/assets/iconos-visualiza/Grafica-B.svg" alt="" />
                  <span>Gráfica</span>
                </span>
                <span v-else>
                  <img src="@/assets/iconos-visualiza/O_Mapa-B.svg" alt="" />
                  <span>Mapa</span>
                </span>
              </button>
              <button
                :class="{ 'visualizar-activo': !mostrarGrafica }"
                @click="manejarMostrarGrafica(false)"
              >
                <span>
                  <img src="@/assets/iconos-visualiza/Tarjetas-A.svg" alt="" />
                  <span>Tarjetas</span>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div v-if="eleccion === 'Gubernatura'">
          <GubernaturaTarjetas :votos-props="votosXCandidatoTarjeta" v-if="!mostrarGrafica" />
          <GubernaturaGraficas :votos-props="votosXCandidatoGraficas" v-else />
        </div>
        <div v-else-if="eleccion === 'Ayuntamientos'" class="mb-4">
          <ResumenMapaAyuntamiento
            :key="random"
            :detalle="detalle"
            v-on:childToParent="mapaDetalles"
            v-if="mostrarGrafica"
          />
          <ResumenTarjetasAyuntamientos v-else :detalle="votosXDistritoTarjeta" />
        </div>
        <div v-else-if="eleccion === 'Diputaciones'" class="votos-por-distritos">
          <ResumenTarjetasAyuntamientos v-if="!mostrarGrafica" :detalle="votosXDistritoTarjeta" />
          <ResumenMapaAyuntamiento
            :key="random"
            :detalle="detalle"
            :esDiputaciones="true"
            v-on:childToParent="mapaDetalles"
            v-else
          />
        </div>

        <div class="resumen">
          <div class="resumen-votacion" v-if="eleccion == 'Gubernatura'">
            <h3>Resumen de la votación</h3>
            <div>
              <div class="cuadro-principal">
                <div>
                  <h4>En la Entidad</h4>
                  <h5>
                    {{
                      gubernaturaResumenNivelEntidad.total_votos_por_entidad_numero
                    }}
                  </h5>
                  <h6>
                    {{
                      gubernaturaResumenNivelEntidad.total_votos_por_entidad_porcentaje
                    }}%
                  </h6>
                </div>
                <div class="icono-mas">
                  +
                </div>
                <div>
                  <h4>En el extranjero</h4>
                  <h5>
                    {{
                      gubernaturaResumenNivelEntidad.total_votos_extranjero_numero
                    }}
                  </h5>
                  <h6>
                    {{
                      gubernaturaResumenNivelEntidad.total_votos_extranjero_porcentaje
                    }}%
                  </h6>
                </div>
              </div>
              <span id="tooltip">
                <font-awesome-icon
                  icon="info-circle"
                  class="fa-sm fa-info extranjero"
                />
                <span class="tooltiptext">
                  Es la sumatoria de los votos obtenidos <br />
                  por Partido Político, Coalición <br />
                  o Candidatura Independiente.
                </span>
              </span>
              <div class="icono-mas">
                +
              </div>
              <div>
                <h4>Candidaturas no registradas</h4>
                <h5>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_numero
                  }}
                </h5>
                <h6>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_porcentaje
                  }}%
                </h6>
              </div>
              <div class="icono-mas">
                +
              </div>
              <div>
                <h4>Nulos</h4>
                <h5>
                  {{ gubernaturaResumenNivelEntidad.total_votos_nulos_numero }}
                </h5>
                <h6>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_nulos_porcentaje
                  }}%
                </h6>
              </div>
              <div class="icono-igual">
                =
              </div>
              <div class="total-de-votos">
                <h4>Total de votos</h4>
                <h5>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_general_numero
                  }}
                </h5>
                <h6>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_general_porcentaje
                  }}%
                </h6>
              </div>
            </div>
          </div>

          <div class="resumen-votacion" v-else>
            <h4 class="mb-2">Resumen de la votación</h4>
            <div class="grilla-suma">
              <div class="acumulados text-center">
                <p class="font-weight-bold">
                  Votos acumulados
                  <span id="tooltip">
                    <font-awesome-icon
                      icon="info-circle"
                      class="fa-sm fa-info"
                    />
                    <span class="tooltiptext">
                      Es la sumatoria de los votos obtenidos <br />
                      por Partido Político, Coalición <br />
                      o Candidatura Independiente.
                    </span>
                  </span>
                  <br />
                  {{ gubernaturaResumenNivelEntidad.total_votos_por_entidad_numero }} <br />
                  <span class="porcentaje">
                    {{ gubernaturaResumenNivelEntidad.total_votos_por_entidad_porcentaje }}%
                  </span>
                </p>
              </div>
              <div class="icono-suma">
                <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <div class="no-registradas text-center">
                <p class="font-weight-bold">
                  Candidaturas no registradas <br />
                  {{ gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_numero }}
                  <br />
                  <span class="porcentaje">
                    {{
                      gubernaturaResumenNivelEntidad.total_votos_candidatura_noregistrada_porcentaje
                    }}%
                  </span>
                </p>
              </div>
              <div class="icono-suma">
                <font-awesome-icon icon="plus" class="fa-2x" />
              </div>
              <div class="nulos text-center">
                <p class="font-weight-bold">
                  Nulos <br />
                  {{ gubernaturaResumenNivelEntidad.total_votos_nulos_numero }} <br />
                  <span class="porcentaje">
                    {{ gubernaturaResumenNivelEntidad.total_votos_nulos_porcentaje }}%
                  </span>
                </p>
              </div>
              <div class="icono-suma">
                <font-awesome-icon icon="equals" class="fa-2x" />
              </div>
              <div class="total-de-votos">
                <h4>Total de votos</h4>
                <h5>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_general_numero
                  }}
                </h5>
                <h6>
                  {{
                    gubernaturaResumenNivelEntidad.total_votos_general_porcentaje
                  }}%
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="votos-extranjero" v-if="eleccion === 'Gubernatura'">
        <div>
          <font-awesome-icon icon="globe-americas" />
          <h2>Voto en el extranjero</h2>
          <router-link to="/G/VE/" class="boton-azul">Ver Actas</router-link>
        </div>
      </div>
      <estadistica-entidad />
      <div class="text-center">
        <p>
          Los resultados presentados tienen un carácter informativo y no son
          definitivos, por tanto, no tienen efectos jurídicos. <br />
          Con base en la Ley Federal del Derecho de Autor queda prohíbida
          cualquier modificación al diseño de este sitio. <br />
          La modificación y/o destrucción de la información y/o contenido total
          o parcial de este sitio, es delito federal de acuerdo al Código Penal
          Federal.
        </p>
      </div>
    </Cuerpo>
    <Compartir />
    <PieDePagina />

    <!-- <detalle-eleccion-candidaturas
      v-if="anchoPantalla >= 992 && eleccion === 'Gubernatura'"
      :resumen="gubernaturaResumenNivelEntidad"
      :votos="votosXCandidatoTarjeta"
      :esCandidato="true"
    />
    <detalle-eleccion-distritos
      v-if="anchoPantalla >= 992 && eleccion === 'Diputaciones'"
      :resumen="gubernaturaResumenNivelEntidad"
      :votos="votosXDistritoTarjeta"
      :esXDistritos="true"
    />
    <detalle-eleccion-distritos
      v-if="anchoPantalla >= 992 && eleccion === 'Ayuntamientos'"
      :resumen="gubernaturaResumenNivelEntidad"
      :votos="votosXDistritoTarjeta"
      :esXAyuntamientos="true"
    /> -->
  </div>
</template>

<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo';
import Compartir from '@/components/Compartir.vue';
import EstadisticaEntidad from '@/components/EstadisticaEntidad.vue';
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue';

import GubernaturaTarjetas from '@/components/Escritorio/componentes/Gubernatura/Tarjetas.vue';
import GubernaturaGraficas from '@/components/Escritorio/componentes/Gubernatura/Graficas.vue';
import ResumenTarjetasAyuntamientos from '../componentes/ResumenTarjetasAyuntamientos.vue';
import ResumenMapaAyuntamiento from '../componentes/ResumenMapaAyuntamiento.vue';

export default {
  name: 'VotosPorCandidaturasEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    EstadisticaEntidad,
    Compartir,
    PieDePagina,
    ResumenMapaAyuntamiento,
    ResumenTarjetasAyuntamientos,
    GubernaturaTarjetas,
    GubernaturaGraficas,
  },
  data() {
    return {
      mostrarGrafica: true,
      altoVotos: 10,
      esMapa: true,
      region: Object,
      random: 0,
      valueAyuntamientos: 0,
    };
  },
  computed: {
    eleccion() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      }
      if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      }
      if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }
      return '';
    },
    detalle() {
      return {
        datos: this.votosXDistritoTarjeta,
        eleccion: this.$route.params.eleccion,
        esMapa: this.esMapa,
      };
    },
    tituloEleccion() {
      if (this.$route.params.eleccion === 'G') {
        return 'Votos por Candidatura';
      }
      if (this.$route.params.eleccion === 'D') {
        return 'Votos Por Distritos';
      }
      if (this.$route.params.eleccion === 'A') {
        return 'Votos Por Municipios';
      }

      return '';
    },
    resumenNivelDistrito() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelDistrito;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelDistrito;
      }
      return this.$store.state.gResumenNivelDistrito;
    },
    gubernaturaResumenNivelEntidad() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelEntidad;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelEntidad;
      }
      return this.$store.state.gResumenNivelEntidad;
    },
    votosXCandidatoTarjeta() {
      return this.$store.state.votosXCandidatoTarjeta;
    },
    votosXCandidatoGraficas() {
      return this.$store.state.votosXCandidatoGraficas;
    },
    votosXDistritoTarjeta() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosMapa;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosMapa;
      }

      return {};
    },
    votosXAyuntamientoTarjeta() {
      return this.$store.state.aVotosMapa;
    },
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
    esDiputaciones() {
      return this.region.esDiputaciones ? 'Distrito' : 'Municipio';
    },
  },
  methods: {
    manejarMostrarGrafica(value) {
      this.mostrarGrafica = value;
    },
    calcularAltoSobrante(vCandidato) {
      const factor = this.altoVotos * 5 + 15;
      const valH = (factor
          * vCandidato
            .candidatura_total_votos_general_porcentaje)
        / 100;
      return `padding-top:${String(factor - valH)}px;`;
    },
    calcularEstiloElemento(vCandidato) {
      const factor = this.altoVotos * 5;
      const valH = (factor
          * vCandidato
            .candidatura_total_votos_general_porcentaje)
        / 100;
      return (
        `${'width: 56px;'
        + 'height:'}${
          String(valH)
        }px;`
        + `z-index:1; background-color:${
          vCandidato.candidatura_color
        };`
      );
    },
    mapaDetalles(value) {
      this.region = value;
      this.clickVolver(this.region.esMapa);
    },
    clickVolver(value) {
      this.esMapa = value;
      this.manejarMostrarGrafica(false);
    },
    onCheckboxChange() {
      this.$forceUpdate();
      this.random = Math.random();
    },
    actualizarRandomKey(modal) {
      // console.log('aqui estoy', modal)
      this.random = Math.random();
      this.$bvModal.show(modal);
    },
  },
  mounted() {
    if (this.$refs.panelVotos !== undefined) this.altoVotos = this.$refs.panelVotos.clientHeight;
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.$forceUpdate();
        this.random = Math.random();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.votos-extranjero {
  background-image: url("../../../assets/Fondo_Mapa.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
