<template>
  <div style="display:block;">
    <section v-if="detalle.esMapa" class="resumen-ayuntamientos">
      <div class="grilla">
        <div class="tabla">
          <div class="grilla-interna">
            <div class="borde h-100">
              <h4 class="font-weight-bold mb-0" v-if="!esDiputaciones">
                Municipios Obtenidos
              </h4>
              <h4 class="font-weight-bold mb-0" v-else>
                Distritos Obtenidos
              </h4>
            </div>
            <div class="grilla-interna h-100">
              <h4 class="font-weight-bold mb-0">
                Votos
              </h4>
              <p class="text-left mb-0">
                Porcentaje <br />
                de Votos
              </p>
            </div>
          </div>

          <div
            :class="
              candidato.candidatura_id ==
              detalle.datos.candidatura_id_con_mayor_numero_votos
                ? 'grilla-interna mayor'
                : 'grilla-interna'
            "
            v-for="(candidato, index) in detalle.datos.datos_candidatos"
            :key="index"
          >
            <div class="borde h-100">
              <div class="grilla-interna mb-3">
                <div
                  class="contenedor-imagen"
                  :style="{ 'border-color': candidato.candidatura_color }"
                >
                  <img
                    class="foto-candidatura"
                    :src="candidato.candidatura_logo"
                    alt=""
                  />
                </div>
                <div class="text-right mr-3">
                  <h2 class="font-weight-bold">
                    {{
                      eleccion === "D"
                        ? candidato.candidatura_distritos_obtenidos_numero
                        : candidato.candidatura_municipios_obtenidos_numero
                    }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="grilla-interna h-100 mb-3">
              <h4 class="font-weight-bold mb-0">
                {{ candidato.candidatura_total_votos_numero }}
              </h4>
              <div>
                <p class="text-left mb-0">
                  {{ candidato.candidatura_total_votos_porcentaje }}%
                </p>
                <b-progress max="100">
                  <b-progress-bar
                    :value="candidato.candidatura_total_votos_porcentaje"
                    :style="{ 'background-color': candidato.candidatura_color }"
                  />
                </b-progress>
              </div>
            </div>
          </div>

          <div class="grilla-interna mt-3">
            <div class="grilla-interna">
              <h4 class="text-center font-weight-bold">
                Total
              </h4>
              <h4 class="text-right font-weight-bold mr-3">
                {{ calcularTotal }}
              </h4>
            </div>
          </div>
        </div>
        <div class="mapa h-100">
          <p class="mensaje" v-if="!esDiputaciones">
            <b>
              Mapa de Municipios
            </b>
            <br />
            El mapa resalta en los Municipios el Partido Político, Coalición o
            Candidatura Independiente que llevan ventaja al momento.
          </p>
          <p class="mensaje" v-else>
            <b>
              Mapa de Distritos Electorales
            </b>
            <br />
            El mapa resalta en los Distritos Electorales el Partido Político,
            Coalición o Candidatura Independiente que llevan ventaja al momento.
          </p>
          <SvgPanZoom
            style="width: 100%; height: 650px; border:1px solid black;"
            :zoomEnabled="true"
            :controlIconsEnabled="true"
            :fit="true"
            :center="true"
            @svgpanzoom="registerSvgPanZoom"
          >
            <svg-map
              v-model="selectedLocation"
              :map="Municipios"
              style="width:100% !important; height:100%;"
              v-if="!esDiputaciones"
            />
            <svg-map
              class="mapa-distritos"
              v-model="selectedLocation"
              :map="Distritos"
              style="width:100% !important; height:100%;"
              v-else
            />
          </SvgPanZoom>
          <Tooltip
            v-if="seleccionRegion"
            :region="this.region"
            v-on:tooltipFocus="actualizarTooltip"
          ></Tooltip>
        </div>
      </div>

      <div class="row" v-if="eleccion === 'D'">
        <div class="col-12">
          <p>
            <b>
              No se considera la cantidad de votos asentada en las
              Actas PREP de la Casilla Especial de la Representación Proporcional.
            </b>
          </p>
        </div>
      </div>
    </section>
    <ResumenTarjetasAyuntamientos
      v-else
      :detalle="detalle.datos"
    ></ResumenTarjetasAyuntamientos>
  </div>
</template>

<script>
import { SvgMap } from 'vue-svg-map';
import SvgPanZoom from 'vue-svg-pan-zoom';
import Municipios from '@/assets/municipios';
import Distritos from '@/assets/distritos';
import Tooltip from '@/components/Escritorio/componentes/Tooltip.vue';
import ResumenTarjetasAyuntamientos from './ResumenTarjetasAyuntamientos.vue';
import 'vue-svg-map/dist/index.css';

export default {
  name: 'ResumenMapaAyuntamiento',
  components: {
    SvgMap,
    Tooltip,
    ResumenTarjetasAyuntamientos,
    SvgPanZoom,
  },
  data() {
    return {
      Municipios,
      Distritos,
      selectedLocation: null,
      mapa: Array,
      region: {
        type: Array,
        defautl: () => [],
      },
      seleccionRegion: false,
      seleccion: Object,
      tooltipActivo: false,
      svgpanzoom: null,
    };
  },
  props: {
    detalle: Object,
    esDiputaciones: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.cargarEventos();
  },
  methods: {
    cargarEventos() {
      const elemento = document.getElementsByClassName('svg-map__location');

      elemento.forEach((r) => {
        r.addEventListener(
          'mouseover',
          (e) => {
            this.desplegarRegion(e, r);
          },
          false,
        );

        r.addEventListener(
          'click',
          () => {
            this.detalleMapa(r);
          },
          false,
        );
        let color = '';
        if (this.eleccion === 'D') {
          color = this.regionSeleccionada?.datos_distritos.filter(
            (t) => t.distrito_id == r.getAttribute('finder'),
          )[0].candidatura_ganadora_color;
        } else {
          color = this.regionSeleccionada.datos_municipios.filter(
            (t) => t.municipio_id == r.getAttribute('finder'),
          )[0].candidatura_ganadora_color;
        }
        r.style.fill = color;
      }, this);

      this.mapa = elemento;
    },
    obtenerRegionTooltip(id) {
      const distrito = this.detalle.datos.datos_candidatos.filter(
        (r) => r.candidatura_id == id,
      )[0];
      return distrito;
    },
    candidaturaGanadora(id) {
      let idGanadora;
      if (this.eleccion === 'D') {
        idGanadora = this.regionSeleccionada.datos_distritos.filter(
          (t) => t.distrito_id == id,
        )[0].candidatura_id_ganadora;
      } else {
        idGanadora = this.regionSeleccionada.datos_municipios.filter(
          (t) => t.municipio_id == id,
        )[0].candidatura_id_ganadora;
      }
      return idGanadora;
    },
    desplegarRegion(e, r) {
      if (this.seleccionRegion) {
        this.mostrarRegion();
      }
      this.$nextTick(() => {
        this.region.estado = true;
        this.region.map = r;
        const candidaturaGanadora = this.candidaturaGanadora(r.getAttribute('finder'));
        if (candidaturaGanadora != 0) {
          this.region.datos = this.obtenerRegionTooltip(candidaturaGanadora);
          this.region.datos.ganadora = true;
        } else {
          this.region.datos = undefined;
        }
        this.region.event = e;
        this.region.tipo = this.detalle.eleccion;
        this.region.esDiputaciones = this.esDiputaciones;
        this.seleccionRegion = true;
        this.mapa.forEach((m) => {
          if (m.id != r.id) {
            m.classList.add('opacidad_mapa');
          } else {
            m.classList.remove('opacidad_mapa');
          }
        });
      });
    },
    mostrarRegion() {
      this.seleccionRegion = false;
      this.mapa.forEach((m) => {
        m.classList.remove('opacidad_mapa');
      });
    },
    detalleMapa(r) {
      this.$router.push(
        `/${this.eleccion}/DET/VPC/${r.getAttribute('finder')}`,
      );
      this.actualizarEstado(r);
      this.detalle.datos.elemento = r;
    },
    actualizarEstado(elemento) {
      this.detalle.esMapa = false;
      this.detalle.region = elemento;
      this.detalle.esDiputaciones = this.esDiputaciones;
      this.$emit('childToParent', this.detalle);
    },
    actualizarTooltip(value) {
      this.tooltipActivo = value;
    },
    registerSvgPanZoom(svgpanzoom) {
      this.svgpanzoom = svgpanzoom;
    },
  },
  watch: {
    esMapa: {
      handler(val) {
        this.$nextTick(() => {
          if (val) {
            this.seleccionRegion = false;
            this.cargarEventos();
          }
        });
      },
      $nextTick: true,
    },
    tooltipActivo: {
      handler(val) {
        if (!val) {
          this.mostrarRegion();
        }
      },
    },
    $route(to, from) {
      this.$nextTick(() => {
        this.cargarEventos();
      });
    },
  },
  computed: {
    regionSeleccionada() {
      if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosMapaTarjetas;
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosMapaTarjetas;
      }
    },
    calcularTotal() {
      let total = 0;
      this.detalle.datos.datos_candidatos.map((partido) => {
        total += parseInt(
          this.eleccion === 'D'
            ? partido.candidatura_distritos_obtenidos_numero
            : partido.candidatura_municipios_obtenidos_numero,
        );
      });

      return total;
    },
    esMapa() {
      return this.detalle.esMapa;
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
  },
};
</script>

<style lang="scss" scoped>
.grilla {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  align-items: center;

  .tabla {
    .grilla-interna {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      align-items: center;

      .contenedor-imagen {
        border-left: 5px solid;
      }
    }
  }

  .mapa {
    display: block;
    position: relative;

    .mensaje {
      max-width: 250px;
    }
  }
}

.borde {
  border-right: 2px solid #E97EBD;
}

.foto-candidatura {
  max-height: 50px;
  /* max-width: 50px; */
}
</style>
